<template>
  <div class="addModel">
    <MapDialog title="绑定设备" width="400px" @comfirm="submit" @cancel="cancel">
      <baseRow>
        <baseCol :num="24">
          <el-form :model="form" :rules="rules" ref="form" label-width="100px">
            <el-form-item label="选择设备" prop="id">
              <MapCascader ref="cascader" :value.sync="form.id" :options="cascader_options" @update="change"></MapCascader>
            </el-form-item>
          </el-form>
        </baseCol>
      </baseRow>
    </MapDialog>
  </div>
</template>
<script>
import MapDialog from '../components/dialog.vue';
import baseRow from '@/components/row/base-row';
import baseCol from '@/components/col/base-col';
import MapCascader from '../components/cascader.vue';
import { maxPageSize } from '/app.config.js';
export default {
  data() {
    const validator = (rule, value, callback) => {
      if (value.length < 4) {
        callback('请选择设备');
      } else {
        callback();
      }
    };
    return {
      form: {
        id: ''
      },
      cascader_options: {
        options: [],
        props: {
          label: 'name',
          value: 'id',
          lazy: true,
          lazyLoad: async (node, resolve) => {
            const { root, value, children } = node;
            const notFactory = !root;
            const isProductionLine = notFactory && children.length === 0;

            if (isProductionLine) {
              const params = {
                path: value,
                currentPageSize: maxPageSize
              };
              const items = await this.$apis.LSDconfig.getdevice(params);
              const device_list = items.map(device => {
                return {
                  ...device,
                  id: device.id,
                  label: device.name,
                  leaf: true
                };
              });
              resolve(device_list);
            } else {
              resolve();
            }
          }
        }
      },
      rules: {
        id: [{ required: true, validator, trigger: 'change' }]
      }
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      const factoryId = JSON.parse(localStorage.getItem('FactoryMap')).id;
      const factory_list = (await this.$apis.FactoryChart.list()).map(item => {
        return {
          ...item,
          label: item.name
        };
      });

      this.cascader_options.options = factory_list.filter(factory => factory.id === Number(factoryId));
    },
    change(ids) {
      const NotDevice = ids.length < 4;

      if (NotDevice) {
        this.form.id = '';
        this.$refs.cascader.value = [];
      }
    },
    cancel() {
      this.$router.replace({ path: '/map/editor' });
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = JSON.stringify({
            nodeId: this.$route.query.id,
            deviceId: this.form.id.pop()
          });

          this.$message.success('绑定成功');

          localStorage.setItem('bind_device_node', data);
          this.$router.replace({ path: '/map/editor' });
        }
      });
    }
  },
  components: {
    baseRow,
    baseCol,
    MapDialog,
    MapCascader
  }
};
</script>

<style lang="scss" scoped>
@import '../style/index.scss';
</style>
