<template>
  <div class="MapCascader">
    <el-cascader v-model="value" v-bind="options" @change="handleChange" popper-class="MapCascader-cascader"></el-cascader>
  </div>
</template>

<script>
export default {
  props: {
    options: Object
  },
  data() {
    return {
      value: []
    };
  },
  methods: {
    handleChange(val) {
      this.$emit('update', val);
      this.$emit('update:value', val);
    }
  }
};
</script>

<style lang="scss">
@import '@/common/style/var';
@import '../style/index.scss';
.MapCascader-cascader {
  border-color: #222 !important;
  background: #060606 !important;
  .el-cascader-menu {
    color: #bdbdbd;
    border-right-color: #222;
  }
  .el-cascader-node:not(.is-disabled):hover,
  .el-cascader-node:not(.is-disabled):focus {
    color: $--color-primary;
    background: #121212 !important;
  }
  .popper__arrow::after {
    top: 0 !important;
    border-bottom-color: #060606 !important;
  }
}
</style>
